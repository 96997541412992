import React from 'react'
import "./sign.css"

function Sign() {

    let myDate = new Date()

let someDay = myDate.getDate();
let someMonth = myDate.getMonth() + 1;
let someYear = myDate.getFullYear();


  return (
    <div className='sign'>
        <p className='sign-desc'>Sva prava pridržana · {`${someDay}. ${someMonth}. ${someYear}.`} · Made by <a href="https://mathscript.eu" target="_blank" rel="noreferrer">Mathscript</a></p>

    </div>
  )
}

export default Sign