import React from 'react'
import './services.css'

import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'

import eu from '../../files/eu.jpeg'
import euro from '../../files/euro.jpeg'
import bank from '../../files/bank.jpeg'

function Services() {
  return (
    <div id='usluge' className='services'>
      <h1 className='main-label'>Što vam možemo pružiti?</h1>
      <ul className='serv-start'>
        <li>
          <PlaylistAddCheckIcon className='icons' /> EU FONDOVI I POTPORE
        </li>
        <li>
          <PlaylistAddCheckIcon className='icons' /> Informiranje o aktualnim
          natječajima za dodjelu bespovratnih sredstava iz fondova EU
        </li>
        <li>
          <PlaylistAddCheckIcon className='icons' />
          Financijski instrumenti EU – krediti, zajmovi i jamstva
        </li>
        <li>
          <PlaylistAddCheckIcon className='icons' />
          POSLOVNO I FINANCIJSKO SAVJETOVANJE
        </li>
        <li>
          <PlaylistAddCheckIcon className='icons' />
          SAVJETOVANJE U BANKARSKOM POSLOVANJU
        </li>
        <li>
          <PlaylistAddCheckIcon className='icons' />
          Priprema kreditnih zahtjeva po sistemu „ključ u ruke“
        </li>
        <li>
          <PlaylistAddCheckIcon className='icons' />
          Izrade poslovnih operativnih planova u slučaju potrebe vanjskog
          financiranja i realizacije investicijskih projekata
        </li>
        <li>
          <PlaylistAddCheckIcon className='icons' />
          Izrade ekspertnih mišljenja iz područja poreza, računovodstva i
          financija
        </li>
        <li>
          <PlaylistAddCheckIcon className='icons' />
          Stručna pomoć u pripremi Društva za eventualni Porezni nadzor
        </li>
      </ul>

      <div className='serv-desc'>
        <h2>EU FONDOVI I POTPORE</h2> <br />
        <p>
          <b>Pripremamo projekte i odrađujemo provjere isplativosti prijave.</b>{' '}
        </p>
        <p>
          <b>Osmišljavamo projekte.</b> Ako imate ideju mi ćemo Vam pomoći da je
          pretvorite u stvarnost.
        </p>
        <p>
          Izradit ćemo projektnu prijavu i poslovni plan u kojem ćemo sagledati
          rezultate, ciljeve, proračun, aktivnosti i rizike provedbe. Projektom
          ćemo definirati kako će ulaganje utjecati na buduće poslovanje, koje
          su aktivnosti potrebne za njegovo provođenje, koji rizici ga mogu
          omesti, tko će ga provesti i na koji način provedba projekta može biti
          financirana.
        </p>
        <p>
          <b>Provjera isplativosti prijave.</b> Sve natječaje za dodjelu
          bespovratnih potpora iz EU strukturnih i investicijskih fondova, kao i
          za natječaje putem kojih se dodjeljuju potpore iz državnog i lokalnih
          proračuna, raspisuju ministarstava, agencije, županije i općine. Svaki
          natječaj definira tko se na njega može prijaviti, kakve projekte je
          moguće prijaviti, koje aktivnosti i troškovi su prihvatljivi za
          financiranje, na koji način se ocjenjuju prijave.
        </p>
        <p>
          Sve natječaje za dodjelu bespovratnih potpora iz EU strukturnih i
          investicijskih fondova, kao i za natječaje putem kojih se dodjeljuju
          potpore iz državnog i lokalnih proračuna, raspisuju ministarstava,
          agencije, županije i općine. Svaki natječaj definira tko se na njega
          može prijaviti, kakve projekte je moguće prijaviti, koje aktivnosti i
          troškovi su prihvatljivi za financiranje, na koji način se ocjenjuju
          prijave.
        </p>
        <p>
          <b>
            Provjera isplativosti uključuje našu provjeru može li Vaš projekt
            dobiti minimum bodova na natječaju potreban za dodjelu potpore.
          </b>
        </p>
        <p>
          <b>Prijava projekta.</b> Za prijavu na natječaj za dodjelu
          bespovratnih potpora iz EU strukturnih i investicijskih fondova i
          natječaje putem kojih se dodjeljuju potpore iz državnog i lokalnih
          proračuna biti će Vam potrebno jedan, dva ili sva tri od sljedećih
          dokumenata:
        </p>
        <p>
          1.{' '}
          <i>
            Projektni sažetak koji opisuje ciljeve projekta, utjecaj na ciljane
            skupine, aktivnosti provođenja, ljudske resurse potrebne za
            provedbu, preduvjete za realizaciju, izvore sredstava, rizike
            provođenja, kvantifikatore uspješnosti realizacije projekta i
            ostvarivanja ciljeva, proračun i njegovu isplativost.
          </i>
        </p>
        <p>
          2.{' '}
          <i>
            Poslovni plan u kojem se opisuje cjelokupno poslovanje, proizvode
            ili usluge, tržište, marketing, operativno poslovanje i prognoze
            financijske uspješnosti i pozicije prijavitelja. Poslovni plan
            omogućuje uvid u ukupno poslovanje prijavitelja, procjenu njegove
            stabilnosti što je ključan element održivosti rezultata i ciljeva
            projekta.
          </i>
        </p>
        <p>
          3.{' '}
          <i>
            Investicijska studija ili studija izvodljivosti biti će potrebna kod
            prijava relativno većih projekata za dodjelu bespovratnih potpora iz
            EU fondova, a u njemu su detaljnije opisani učinci, troškovi i
            koristi projekta.
          </i>
        </p>
        <p>
          <b>
            Prijava na natječaj za dodjelu bespovratnih potpora uključuje i
            popunjavanje niza dodatnih obrazaca i prikupljanje niza
            administrativnih potvrda.
          </b>
        </p>
        <p>
          Nudimo Vam uslugu pripreme prijave u skladu s natječajnom
          dokumentacijom, što obuhvaća{' '}
          <b>pomoć pri izradi svih dokumenata prijave.</b>
        </p>
        <p>
          <b>Provedba projekta i izvještavanje.</b> Ukoliko nadležno tijelo
          donese pozitivnu odluku o dodjeli bespovratnih sredstava, biti ćete
          dužni s istim zaključiti ugovor, kojim se definiraju daljnje obveze za
          vrijeme trajanja projekta, kao i nakon njegovog završetka.
        </p>
        <p>
          Ugovorom se utvrđuje način korištenja bespovratnih sredstava, kao i
          obveza izvještavanja o provođenju projekta. Najčešće je potrebno
          kvartalno izvještavanje, nakon završetka projekta, a ponekad i
          nekoliko godina nakon njegovog završetka kako bi se utvrdilo jesu li
          ostvareni ciljevi zbog kojih je projekt ostvario sufinanciranje iz EU
          fondova.
        </p>
        <img
          src={eu}
          alt='Zastava Europske unije, organizacije koja iz svojih fondova financira i podupire malo, srednje i veliko poduzetništvo, a gdje potporu pruža financijsko savjetovanje i konzalting Lučić & Partneri'
          draggable='false'
          className='serv-img'
        />
        <h2>POSLOVNO I FINANCIJSKO SAVJETOVANJE</h2> <br />
        <p>
          Nudimo Vam uslugu upravljanja realizacijom projekta u dijelu
          usklađenosti projekta s odredbama zaključenog ugovora i natječaja po
          kojem je bespovratna potpora dodijeljena te za Vas izrađujemo potrebne
          izvještaje.
        </p>
        <p>
          <b>
            Poslovnim planovima, predinvesticijskim i investicijskim studijama
            se na sistematičan i analitički način oblikuju Vaši poslovni ciljevi
            i ideje te iskazuju njihovi financijski i društveni efekti.
          </b>
        </p>
        <p>
          Našim stručnim i detaljnim pristupom, kao rezultatom višegodišnjeg
          iskustva u sektoru bankarstva, korporativnog poslovanja i
          poduzetništva, pomažemo u ostvarenju Vaših financijskih i poslovnih
          ciljeva. Nudimo Vam uslugu izrade poslovnih planova, a prema potrebi,
          usluga može sadržavati i istraživanje tržišta radi utvrđivanja tržišne
          opravdanosti ulaganja.
        </p>
        <p>
          Između ostaloga,{' '}
          <b>
            izrađujemo analize financijskih izvještaja, procjene vrijednosti
            poduzeća i financijski kontroling
          </b>
          , a kako biste dodatno povećali uspješnost Vaših projekata izrađujemo
          i marketinške planove, investicijske studije te cost benefit analize.
        </p>
        <img
          src={euro}
          alt='Novčanice valute euro - financijsko savjetovanje i konzalting Lucić & Partneri'
          draggable='false'
          className='serv-img'
        />
        <h2>SAVJETOVANJE U BANKARSKOM POSLOVANJU</h2> <br />
        <p>
          Imamo bogato iskustvo u savjetovanju u bankarskom poslovanju,{' '}
          <b>
            što uključuje i pripremu projekta u svrhu pronalaska modela
            financiranja koji je za Vas najoptimalniji.{' '}
          </b>{' '}
          Naša usluga uključuje izradu investicijske studije, kao podloge za
          pronalazak izvora kreditiranja. Osiguravamo potpunu savjetodavnu
          podršku u pregovoru s financijskim i kreditnim institucijama.
        </p>
        <p>
          <b>Između ostaloga, nudimo Vam:</b>
        </p>
        <p>
          1. <i>analizu projekta i popratne dokumentacije,</i>
        </p>
        <p>
          2. <i>pomoć u odabiru optimalnog poslovnog modela,</i>
        </p>
        <p>
          3.{' '}
          <i>
            razvoj optimalnog modela financiranja koji uključuje i izradu
            investicijske studije za potrebe kreditiranja,
          </i>
        </p>
        <p>
          4.{' '}
          <i>
            po potrebi sudjelovanje u razgovorima i pregovorima s financijskim i
            kreditnim institucijama.
          </i>
        </p>
        <p>Za sve dodatne informacije, stojimo na raspolaganju.</p>
        <img
          src={bank}
          alt='Bankarsko savjetovanje Lucić & Partneri'
          draggable='false'
          className='serv-img'
        />
      </div>
    </div>
  )
}

export default Services
