import React from "react";
import "./about.css";

import image1 from "../../files/image1.jpeg";
import image2 from "../../files/image2.png";
import image3 from "../../files/image3.jpeg";
import image4 from "../../files/image4.jpeg";

function About() {
  return (
    <div id="o-nama" className="about">
      <h1 className="main-label">Tko smo mi?</h1>
      <div className="about-box">
        <p className="box1">
          Usko smo specijalizirani i licencirani obrt za financijske
          instrumente EU (kredite, zajmove i jamstva) i bespovratna sredstva
          fondova EU. Osnivači obrta, odnosno zaposlenici obrta su osobe sa
          dugogodišnjim radom i iskustvom u poslovanju trgovačkih društava u
          Republici Hrvatskoj.
        </p>
        <img
          src={image1}
          alt="LUCIĆ & PARTNERI za usluge financijskog i poslovnog savjetovanja - financijska i poslovna izvješća i natječaji za bolje poslovanje tvrtke ili obrta"
          draggable="false"
          className="about-img box2"
        />

        <p className="box3">
          LUCIĆ PARTNERI Vam pružaju usluge istraživanja mogućnosti financiranja
          projektnih ideja, pripreme i prijave na natječaje za bespovratna EU
          sredstva i dobivanje povoljnih zajmova, kredita i jamstava -
          financijski instrumenti EU.
        </p>
        <img
          src={image2}
          alt="LUCIĆ & PARTNERI za usluge financijskog i poslovnog savjetovanja - financijska i poslovna izvješća i natječaji za bolje poslovanje tvrtke ili obrta"
          draggable="false"
          className="about-img box4"
        />

        <p className="box5">
          Kao što Vam je poznato, za uspjeh u poslovnom svijetu nisu dovoljna
          samo stručna znanja već su potrebna i temeljna znanja iz područja
          računovodstva, financija i poreza. Također Vam je poznato da je
          upravljanje financijskim resursima ključno za uspjeh i dugoročnu
          stabilnost svakog poduzetnika.
        </p>
        <img
          src={image3}
          alt="LUCIĆ & PARTNERI za usluge financijskog i poslovnog savjetovanja - financijska i poslovna izvješća i natječaji za bolje poslovanje tvrtke ili obrta"
          draggable="false"
          className="about-img box6"
        />

        <p className="box7">
          Naš tim čine profesionalci s bogatim iskustvom na području kreditnog i
          financijskog poslovanja koji će Vam osigurati cjelovitu uslugu
          prilagođenu Vašem poslovanju i konkretnom projektu. Naša je posebnost
          u tome što svaku Vašu ideju i projekt možemo sagledati s tri bitna
          aspekta – financijskog, pravnog i projektnog.
        </p>
        <img
          src={image4}
          alt="LUCIĆ & PARTNERI za usluge financijskog i poslovnog savjetovanja - financijska i poslovna izvješća i natječaji za bolje poslovanje tvrtke ili obrta"
          draggable="false"
          className="about-img box8"
        />
      </div>
    </div>
  );
}

export default About;
