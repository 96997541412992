import React from 'react'
import esif from '../../files/esif.png'
import esifblue from '../../files/esifblue.png'
import eulogo from '../../files/eulogo.jpeg'
import './footer.css'

function Footer() {
  return (
    <div className='footer'>
      <img
        src={eulogo}
        alt='LUCIĆ & PARTNERI za usluge financijskog i poslovnog savjetovanja - zastava Europske unije'
        draggable='false'
        className='foot-img one'
      />

      <img
        src={esif}
        alt='LUCIĆ & PARTNERI za usluge financijskog i poslovnog savjetovanja - logo Europskih strukturnih i investicijskih fondova (ESIF)'
        draggable='false'
        className='foot-img two'
      />

      <img
        src={esifblue}
        alt='LUCIĆ & PARTNERI za usluge financijskog i poslovnog savjetovanja - plavi logo ESIF-a, Europskih strukturnih i investicijskih fondova za koje društvo piše poslovne planove, prijavu i izradu dokumentacije za natječaje za poduzetnike'
        draggable='false'
        className='foot-img three'
      />

      <div className='four'>
        <p>LUCIĆ PARTNERI, obrt za poslovno savjetovanje</p>
        <p>LUCIĆ PARTNERS, trade for business consulting (ENG)</p>
        <p>LUCIĆ PARTNER Handwerk für Unternehmensberatung (DEU)</p>
        <p>Hrgovići 87, Zagreb 10 000</p>
        <p>Vlasnik: Tomislav Lucić</p>
        <p>info@lucicpartneri.hr</p>
        <p>Matični broj: 98517066</p>
      </div>
    </div>
  )
}

export default Footer
