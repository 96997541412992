import React from "react";
import "./header.css";

import citynight from "../../files/citynight.jpeg";
import logocl from "../../files/logocl.jpeg";

function Header() {
  return (
    <div className="header">
      <img
        src={citynight}
        alt="LUCIĆ PARTNERI - usluge financijskog i poslovnog savjetovanja i pisanja poslovnih planova, planova za EU i druge projekte, za bespovratna sredstva i natječaje za tvrtke, obrte i udruge - djeluje globalno"
        draggable="false"
        className="head-img"
      />
      <div className="head-nav">
        <img
          src={logocl}
          alt="LUCIĆ PARTNERI za usluge financijskog i poslovnog savjetovanja i pisanja poslovnih planova, planova za EU i druge projekte, za bespovratna sredstva i natječaje za tvrtke, obrte i udruge - logo"
          draggable="false"
          className="head-logo"
        />
        <div className="head-list">
          <a href="#o-nama">O nama</a>
          <a href="#usluge">Usluge</a>
          <a href="#kontakt">Kontakt</a>
        </div>
      </div>
      <h1 className="head-h1">LUCIĆ I PARTNERI</h1>
      <p className="head-p">
        Bespovratna sredstva fondova EU i financijski instrumenti EU - krediti,
        zajmovi i jamstva
      </p>
    </div>
  );
}

export default Header;
