import React from 'react'
import './contact.css'

import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection'

function Contact() {
  return (
    <div className='contact' id='kontakt'>
      <h1 className='main-label'>Kako nas možete kontaktirati?</h1>
      <div className='cont-box'>
        <div className='box11'>
          <p>LUCIĆ PARTNERI</p>
          <p>LUCIĆ PARTNERI, obrt za poslovno savjetovanje</p>
          <p>Vlasnik: Tomislav Lucić</p>
        </div>
        <div className='box22'>
          <p>info@lucicpartneri.hr</p>
          <p>+385 (0)91 6072 716</p> <br />
          <p>
            <i>
              Usko smo specijalizirani i licencirani obrt za financijske
              instrumente EU (kredite, zajmove i jamstva) i bespovratna sredstva
              fondova EU. Obrt je pouzdani partner svojim suradnicima, što ima
              utemeljenje kroz dugogodišnji rad i iskustvo u poslovanju unutar
              Republike Hrvatske.
            </i>
          </p>
        </div>
      </div>

      <div className='cont-last'>
        <a
          className='cont-org'
          target='_blank'
          rel='noreferrer'
          href='mailto:info@lucicpartneri.hr'
        >
          <MailOutlineIcon className='cont-icon' />
          <h3>Pošaljite izravno e-mail</h3>
        </a>

        <a
          className='cont-org'
          target='_blank'
          rel='noreferrer'
          href='tel:+385916072716'
        >
          <PhoneInTalkIcon className='cont-icon' />
          <h3>Nazovite nas odmah</h3>
        </a>

        <a
          className='cont-org'
          target='_blank'
          rel='noreferrer'
          href='https://goo.gl/maps/gLnqh2KpCfdTcveB7'
        >
          <AssistantDirectionIcon className='cont-icon' />
          <h3>Naša lokacija na karti</h3>
        </a>
      </div>

      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2782.3531246387493!2d15.924783615568193!3d45.78415277910606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d6a86ba4c1eb%3A0xa07eb39715ca65bc!2sHrgovi%C4%87i%20ul.%2087%2C%2010000%2C%20Zagreb!5e0!3m2!1sen!2shr!4v1648463205691!5m2!1sen!2shr'
        width='280'
        height='450'
        className='map-frame'
        allowFullScreen=''
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
      ></iframe>
    </div>
  )
}

export default Contact
