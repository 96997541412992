import "./App.css";
import CookieConsent from "react-cookie-consent";

import Header from "./components/header/Header";
import About from "./components/about/About";
import Services from "./components/services/Services";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import Sign from "./components/sign/Sign";

function App() {
  return (
    <div className="App">
      <Header />
      <About />
      <Services />
      <Contact />
      <Footer />
      <Sign />

      <CookieConsent
        location="bottom"
        buttonText="U redu"
        cookieName="myAwesomeCookieName2"
        style={{
          background: "goldenrod",
          color: "black",
          fontWeight: "bold",
          boxShadow: "0 0 20px 5px goldenrod",
          fontSize: "11pt",
        }}
        buttonStyle={{
          background: "white",
          color: "black",
          fontSize: "12pt",
          borderRadius: "20px",
          border: "darkgreen solid 3px",
        }}
        expires={150}
      >
        Na ovoj stranici koristimo kolačiće, poznatije pod engleskim nazivom
        "cookies". To su malene datoteke kojima je cilj poboljšati korisničko
        iskustvo za vrijeme korištenja stranica, bez ikakve namjere otkrivanja
        identiteta. Možete maknuti poruku i nastaviti neometano istraživati
        stranicu.
      </CookieConsent>
    </div>
  );
}

export default App;
